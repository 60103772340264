<template>
    <div class="hello">
        <img :src="imgLink" alt="logo" class="logo">
        <h1>{{ gameName }}</h1>
        <h2>Thank you for your patient,</h2>
        <h2>Download will began shortly</h2>
        <div class="download-link" v-if="!isIOS">
            <a href="#" @click="downloadAndroid">Click Here if download did not start</a>
        </div>
    </div>
    {{ downloadLink }}
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        gameName: String,
        imgLink: String,
        AppName: String
    },
    data() {
        return {
            isIOS: false,
            downloadLink: ''
        }
    },
    async created() {
        // 检测设备类型
        this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        await this.checkDevice();
    },
    methods: {
        async checkDevice() {
            const os = this.isIOS ? 'ios' : 'android';
            try {
                const response = await fetch(`https://bo.app96.cc/VueAPI/AppVession.php?AppName=${this.AppName}&os=${os}`);
                const data = await response.json();

                this.downloadLink = data[0].Link;
                if (this.isIOS) {
                    // iOS设备直接跳转
                    window.location.href = this.downloadLink;
                }

            } catch (error) {
                console.error('API调用失败:', error);
            }
        },
        downloadAndroid() {
            window.location.href = this.downloadLink;
        }
    }
}
</script>

<style scoped>
.hello {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.logo {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
}

h1 {
    color: #2c3e50;
    font-size: 2.5em;
    margin-bottom: 20px;
}

h2 {
    color: #2c3e50;
    font-size: 1.5em;
    margin: 10px 0;
}

.download-link {
    margin-top: 20px;
}

.download-link a {
    color: #007bff;
    text-decoration: none;
    font-size: 1.2em;
}

.download-link a:hover {
    text-decoration: underline;
}
</style>